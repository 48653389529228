import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";
import * as userActions from "../../store/actions/user";
import { Spinner } from "react-bootstrap";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

import classes from "./MyPeaksPage.module.scss";

const MyPeaksPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const dispatch = useDispatch();

  const { user, hasAttemptedUserLoad, hasFailedUserLoad } = useSelector(
    (state) => state.userStore
  );

  useEffect(() => {
    const loadUserAsync = async () => {
      if (!hasAttemptedUserLoad) {
        await dispatch(userActions.loadUser());
      }
    };
    loadUserAsync();
  }, [hasAttemptedUserLoad]);

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  let content = (
    <React.Fragment>
      <div
        style={{
          //borderWidth: 1,
          //border: "solid",
          padding: 5,
          backgroundColor: "#2c5986",
          //backgroundColor: "white",
          paddingLeft: 20,
          paddingRight: 20,
          display: "inline-block",
          borderRadius: 5,
          //color: "#2c5986",
          marginBottom: 8,
          fontWeight: "bold",
        }}
      >
        My Peaks
      </div>
      <div className={classes.SubQuestionText}>
        What questions your MtnTop supports, and example phrases to use when
        asking them{" "}
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            alignItems: "flex-start",
            display: "block",
            flexDirection: "column",
            textAlign: "left",
            backgroundColor: "white",
            padding: 15,
            paddingLeft: 25,
            paddingRight: 25,
            borderRadius: 15,
            marginTop: 15,
          }}
        >
          <div className={`${classes.InstructionText}`}>
            <div>
              Your MtnTop supports the following questions:
              <br></br>
              {user &&
              user.skillName &&
              user.samplePhrases &&
              user.samplePhrases.length > 0 ? (
                <React.Fragment>
                  {user.samplePhrases.map((samplePhrase, index) => {
                    return (
                      <React.Fragment key={samplePhrase}>
                        <br></br>
                        {/* <br></br> */}
                        {index + 1}.{" "}
                        <span className={classes.SpokenWords}>
                          Ask {user.skillName}
                        </span>{" "}
                        {samplePhrase}
                      </React.Fragment>
                    );
                  })}
                  <br></br>
                  <br></br>Sample phrases shown above are just examples - your
                  MtnTop can handle many different variations of your supported
                  peaks, as long as the intent of the question remains the same.
                  The words in bold, however, must be said as they are printed.
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <br></br>"MtnTop not configured"
                </React.Fragment>
              )}
              {/*               
              <div style={{ textAlign: "center" }}>
                <span className={classes.SpokenWords} style={{ fontSize: 28 }}>
                  Example:
                </span>
              </div>{" "} */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  if (!hasAttemptedUserLoad)
    content = (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            alignItems: "flex-start",
            display: "block",
            flexDirection: "column",
            textAlign: "left",
            backgroundColor: "white",
            padding: 15,
            paddingLeft: 25,
            paddingRight: 25,
            borderRadius: 15,
            marginTop: 15,
          }}
        >
          <div className={`${classes.LoadingIconContainer}`}>
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    );

  if (hasFailedUserLoad)
    content = (
      <ErrorMessage
        title="Uh oh!"
        subtitle={
          "We are having trouble loading your user profile. Please try again. If the problem persists, be sure to let us know so we can help!"
        }
        style={{ backgroundColor: "white" }}
      ></ErrorMessage>
    );

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            MtnTop
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            {content}
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default withAuth(MyPeaksPage);
