import React, { useState, useEffect, useMemo } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import { withAuth } from "../../hoc/Auth/Auth";
import * as userActions from "../../store/actions/user";
import * as authActions from "../../store/actions/auth";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { apiGet } from "../../shared/apiWrapper";
import classes from "./CollectPaymentPage.module.scss";
import dev_config from "../../config_dev";
import prod_config from "../../config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

const useOptions = () => {
  const fontSize = "14px";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          backgroundColor: "white",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#ec392f",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CollectPaymentPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const { user } = useSelector((state) => state.userStore);

  const dispatch = useDispatch();
  const isAuthed = useSelector((state) => !!state.authStore.authorized);
  const isCheckingSession = useSelector(
    (state) => !!state.authStore.isCheckingSession
  );

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [isLoadingPaymentPage, setIsLoadingPaymentPage] = useState(false);

  useEffect(() => {
    const checkLoggedIn = async () => {
      if (isCheckingSession) {
        try {
          await dispatch(authActions.refreshSession());
        } catch (e) {
          //console.log("Caught error dispatching session refresh action: ", e);
        }
      }
    };
    checkLoggedIn();
  }, [isCheckingSession, dispatch]);

  const loadingMessage = (
    <div className={classes.LoadingIconContainer}>
      <Spinner animation="border" size="lg" role="status" aria-hidden="true" />
    </div>
  );

  if (isCheckingSession) {
    return loadingMessage;
  }
  if (!isAuthed) {
    return <Redirect to={"/auth"}></Redirect>;
  }

  const onActivateClick = async () => {
    // const session = await apiGet(
    //   config.apiGateway.API_NAME,
    //   "/user/self/billing-session"
    // );
    ////console.log("got billing session: ", session);
    // window.location.href = session.session.url;
    setIsLoadingPaymentPage(true);
    const response = await apiGet(
      config.apiGateway.API_NAME,
      "/user/self/checkout-session"
    );
    //console.log("got checkout session: ", response.session);
    await stripe.redirectToCheckout({ sessionId: response.session.id });
  };

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const loadUserAsync = async () => {
  //     if (!hasAttemptedUserLoad) {
  //       await dispatch(userActions.loadUser());
  //     }
  //   };
  //   loadUserAsync();
  // }, [hasAttemptedUserLoad]);

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  const bodyContent = (
    <React.Fragment>
      {" "}
      <span style={{ color: "#444444" }}></span>{" "}
      <span style={{ fontWeight: "bold" }}>Account Activation</span>
      <div className={classes.SubQuestionText}>
        The last step in getting access to your account is providing your
        payment information. You will be charged $15 a month, and you can cancel
        any time!
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* <div className={`col-sm-12 col-lg-6 col-md-6 ${classes.LeftRowItem}`}> */}
        <div className={`${classes.AnswerText}`}>
          <SubmitButton
            disabled={!stripe}
            type="submit"
            onClick={onActivateClick}
            //onSubmissionFinish={onSubmissionFinish}
            hasSuccessfullySubmitted={false}
            isLoading={isLoadingPaymentPage}
            buttonStyle={{
              backgroundColor: "#6d219e",
              color: "white",
              width: "200px !important",
            }}
          >
            ACTIVATE
          </SubmitButton>
          {/* <div
                onClick={() => {
                 //console.log("clicked android");
                  history.push("/android-instructions");
                }}
                className={`${classes.ButtonText}`}
              >
                <div>SET UP PAYMENT</div>
              </div> */}
        </div>
        {/* </div> */}
      </div>
    </React.Fragment>
  );

  const loadingSpinner = (
    <Spinner animation="border" size="lg" role="status" aria-hidden="true" />
  );

  let contentToDisplay = bodyContent;
  // if (!hasAttemptedUserLoad) contentToDisplay = loadingSpinner;
  // if (hasFailedUserLoad)
  //   contentToDisplay = (
  //     <ErrorMessage
  //       title="Uh oh!"
  //       subtitle={
  //         "We are having trouble loading your user profile. Please try again. If the problem persists, be sure to let us know so we can help!"
  //       }
  //     ></ErrorMessage>
  //   );
  // if (hasAttemptedUserLoad && !hasFailedUserLoad) {
  //  //console.log("we have a user, here's the data: ", user);
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <LogoutButton></LogoutButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            MtnTop
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            {contentToDisplay}
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 50,
              }}
            >
              <LogoutButton></LogoutButton>
            </div> */}
          </div>
        )}
      </Transition>
    </div>
  );
};

export default CollectPaymentPage;
