import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import { withRouter } from "react-router";
// import ProtectedContent from "../ProtectedContent/ProtectedContent";
// import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
// import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";
import { CASE_STUDIES } from "../AllCaseStudiesPage/caseStudies";

import classes from "./CaseStudyDetailsPage.module.scss";

const CaseStudyDetailsPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const caseStudyId = props.match.params.caseStudyId;
  //console.log("case study name path param: ", caseStudyId);

  const caseStudyResultsList = CASE_STUDIES.filter((caseStudy) => {
    return caseStudy.id === caseStudyId;
  });

  const caseStudy =
    caseStudyResultsList.length > 0
      ? caseStudyResultsList[0]
      : { businessTitle: "Not found" };

  const defaultWhoTheyAre =
    "This is where I will put a short message explaining who they are, you know, what kind of organization, where, what size, purpose, etc etc etc.";
  const defaultHowTheyUse =
    "This is where I will put a short message explaining how they use MtnTop, you know, what their skill/action name is, what questions they support, their purpose in using it, etc etc etc..";

  const inProp = true;

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div className={`${classes.LogoText}`}>MtnTop</div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            {/* <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    When you want to tell people that your business or
                    organization is now on Alexa and Google Assistant, your
                    communications should include the following:
                  </div>
                </div>
              </div>
            </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 200,
                  height: 200,
                  backgroundColor: "whitesmoke",
                  margin: 10,
                  borderRadius: 6,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  margin: 8,
                }}
              >
                {" "}
                <div className={classes.LogoImage}>
                  {/* <img src={logo} className={"img-fluid"} /> */}
                  <Image src={caseStudy.logoImage} fluid />
                </div>
              </div>
              {/* <div
                style={{
                  //borderWidth: 1,
                  //border: "solid",
                  padding: 5,
                  backgroundColor: "#2c5986",
                  //backgroundColor: "white",
                  paddingLeft: 20,
                  paddingRight: 20,
                  //display: "inline-block",
                  borderRadius: 5,
                  //color: "#2c5986",
                  marginBottom: 8,
                  fontWeight: "bold",
                }}
              >
                {caseStudy.businessTitle}
              </div> */}
            </div>
            {/* <div className={classes.SubQuestionText}>
              How to let your listeners know you are on Alexa and Google
              Assistant and help them find you there{" "}
            </div> */}
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "#2c5986",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                {/* <div
                  style={{
                    //borderWidth: 1,
                    //border: "solid",
                    padding: 5,
                    backgroundColor: "#2c5986",
                    //backgroundColor: "white",
                    paddingLeft: 20,
                    paddingRight: 20,
                    //display: "inline-block",
                    borderRadius: 5,
                    //color: "#2c5986",
                    marginBottom: 8,
                    fontWeight: "bold",
                    alignSelf: "center",
                    textDecoration: "underline",
                  }}
                >
                  {caseStudy.businessTitle}
                </div> */}
                <div
                  style={{
                    //borderWidth: 1,
                    //border: "solid",
                    //padding: 5,
                    backgroundColor: "#2c5986",
                    //backgroundColor: "white",
                    paddingLeft: 20,
                    paddingRight: 20,
                    //display: "inline-block",
                    borderRadius: 5,
                    //color: "#2c5986",
                    //marginBottom: 8,
                    fontWeight: "bold",
                    alignSelf: "flex-start",
                    fontSize: 22,
                    //textDecoration: "underline",
                  }}
                >
                  Who they are:
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    {caseStudy.whoTheyAre
                      ? caseStudy.whoTheyAre
                      : defaultWhoTheyAre}
                  </div>
                </div>
                <div
                  style={{
                    //borderWidth: 1,
                    //border: "solid",
                    //padding: 5,
                    backgroundColor: "#2c5986",
                    //backgroundColor: "white",
                    paddingLeft: 20,
                    paddingRight: 20,
                    //display: "inline-block",
                    borderRadius: 5,
                    //color: "#2c5986",
                    //marginBottom: 8,
                    fontWeight: "bold",
                    alignSelf: "flex-start",
                    fontSize: 22,
                    //textDecoration: "underline",
                  }}
                >
                  How they use MtnTop:
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    {caseStudy.howTheyUse
                      ? caseStudy.howTheyUse
                      : defaultHowTheyUse}
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default withRouter(CaseStudyDetailsPage);
