import React from "react";
import classes from "./CreatorPreinfoPage.module.scss";

const FAQS = [
  {
    question: "What is MtnTop?",
    answer: (
      <div>
        MtnTop is a platform created to allow businesses and organizations to
        connect with their customers or members through Alexa and Google
        Assistant. You choose the name of your MtnTop and the questions it
        supports. Your listeners will then be able to ask Alexa or Google
        Assistant those questions, and their voice assistant of choice will
        respond with whatever content you have posted in the MtnTop app!
        <br></br>
        <br></br>
        For example, let's say you own an ice cream shop called Waffle Cone Ice
        Cream. You could pick the name of your MtnTop to be "Waffle Cone Ice
        Cream", and choose to support questions about your hours and your flavor
        of the day. You would use the MtnTop mobile app to create and post
        answers for these questions. Customers could then say things like "Ask
        Waffle Cone Ice Cream what its hours are today" or "Ask Waffle Cone Ice
        Cream what the flavor of the day is" to their Alexa- or Google
        Assistant-enabled devices, which would then respond with the content you
        had posted.<br></br>
        <br></br>Check out our case studies for examples of businesses and
        organizations leveraging the power of MtnTop to connect with listeners.
      </div>
    ),
  },
  {
    question:
      "Why should I want to have a presence on Alexa and Google Assistant?",
    answer: (
      <div>
        Smart voice assistant technology is transforming the way people access
        and interact with information. Presence on these platforms is rapidly
        becoming a must-have for modern businesses and organizations. Voice
        assistant technology is:
        <br></br>
        <br></br>
        <span style={{ fontWeight: "bold" }}>Wide-reaching:</span>
        <br></br>There are currently an estimated 3.25 billion digital voice
        assistants in use around the world.
        <br></br>
        <br></br>
        <span style={{ fontWeight: "bold" }}>Highly used:</span> <br></br>In
        2018, 56% of consumers used smart voice assistants at least once to get
        information about a business, and 46% used this technology daily.
        <br></br>
        In 2020 voice searches are predicted to account for more than 50% of{" "}
        <span style={{ fontStyle: "italic" }}>all</span> digital searches.
        <br></br>
        <br></br>
        <span style={{ fontWeight: "bold" }}>Growing rapidly:</span>
        <br></br>
        The number of voice assistants in use is expected to grow to 8 billion
        by 2023.
        <br></br>
        <br></br>
        <span style={{ fontWeight: "bold" }}>Everywhere:</span>
        <br></br>
        Alexa and Google Assistant are built into a wide and every-growing range
        of devices. Google Assistant comes with every Android phone, giving you
        quick access to a huge market of Android users. Alexa can now be found
        in devices ranging from headphones to tv's to cars - in fact, about 77.1
        million drivers in the US now use the Alexa technology built into their
        cars! Add to that the in-home assistants such as the Echo and the Google
        Home quickly finding their way into living rooms and onto kitchen
        counters across America, and the wide reach and impact of smart voice
        technology becomes clear!
      </div>
    ),
  },
  {
    question: "Do I get my own custom Alexa skill and Google Action?",
    answer: (
      <div>
        Absolutely! The MtnTop name and brand will not be what your listeners
        interact with on Alexa and Google. You will get your own personal Alexa
        skill and Google action, so you can pick the words that your listeners
        use to trigger it.
      </div>
    ),
  },
  {
    question: "What kind of content can I post on MtnTop?",
    answer: (
      <div>
        The MtnTop mobile app supports two types of posts - Audio and Text.
        <br></br>
        <br></br>
        Audio posts are recordings you can create within the app. When you post
        one as the answer to one of your questions, Alexa or Google plays back
        that audio to your listeners when they ask it, so you can answer
        questions in your own voice!<br></br>
        <br></br>
        Text posts are written messages you can create within in the app. When
        you post one as the answer to one of your questions, Alexa or Google
        reads that text out loud to your listeners in its default voice.
        <br></br>
      </div>
    ),
  },
  {
    question: "How many questions can my MtnTop have?",
    answer: (
      <div>
        There are no restrictions on the number of questions you can set your
        MtnTop up with. Most businesses and organizations select 2 to 5
        questions.
      </div>
    ),
  },
  {
    question: "How often can I update the content posted on my MtnTop?",
    answer: (
      <div>
        You can update it as frequently or infrequently as you like! The MtnTop
        app makes it easy to change what your listeners are hearing in response
        to your questions. It also provides scheduling tools that allow you to
        set in advance what your listeners will hear.
      </div>
    ),
  },

  {
    question: "How much does MtnTop cost?",
    answer: (
      <div>
        MtnTop charges businesses and organizations a monthly fee of $20.{" "}
        <br></br>
        <br></br>
        All MtnTop-hosted Alexa skills and Google actions are free for listeners
        to access.
      </div>
    ),
  },
  {
    question: "How do I help my listeners find me on MtnTop?",
    answer: (
      <div>
        We have created materials to help you tell your audience about your
        MtnTop! Once you log in to your account, you will have access to a
        sample announcement to let people know how to find you on Alexa and
        Google Assistant.
      </div>
    ),
  },
  {
    question: "Why the name MtnTop?",
    answer: (
      <div className={classes.WrappedAnywhere}>
        At MtnTop, our mission is help you get your message to your audience.
        Don't just tell your audience - shout your message from the MtnTop!
      </div>
    ),
  },
  {
    question: "I have a question you didn't answer. What should I do?",
    answer: (
      <div className={classes.WrappedAnywhere}>
        We would love to hear from you! Feel free to contact us at
        mtntop@harbourviewtechnologies.com with any questions or feedback.
      </div>
    ),
  },
];
export { FAQS };
