import React from "react";
import classes from "./AllCaseStudiesPage.module.scss";
import hopeLogo from "../../assets/images/HOPE_Anchor_Stack_small.png";
import peanutLogo from "../../assets/images/peanut_store_logo_small.png";
import weatherfordLogo from "../../assets/images/weatherford_fitness_logo_small.png";

const CASE_STUDIES = [
  {
    id: "hope-college",
    businessTitle: "Hope College",
    logoImage: hopeLogo,
    whoTheyAre:
      "This is where I will put a short message explaining who Hope is, you know, what kind of organization, where, what size, purpose, etc etc etc.",
    howTheyUse:
      "This is where I will put a short message explaining how Hope College uses MtnTop, you know, what their skill/action name is, what questions they support, their purpose in using it, etc etc etc..",
  },
  {
    id: "holland-peanut",
    businessTitle: "Holland Peanut Store",
    logoImage: peanutLogo,
    whoTheyAre:
      "This is where I will put a short message explaining who the Holland Peanut Store is, you know, what kind of organization, where, what size, purpose, etc etc etc.",
    howTheyUse:
      "This is where I will put a short message explaining how the Holland Peanut Store uses MtnTop, you know, what their skill/action name is, what questions they support, their purpose in using it, etc etc etc..",
  },
  {
    id: "weatherford-fit",
    businessTitle: "Weatherford Fitness",
    logoImage: weatherfordLogo,
    whoTheyAre:
      "This is where I will put a short message explaining who Weatherford Fitness is, you know, what kind of organization, where, what size, purpose, etc etc etc.",
    howTheyUse:
      "This is where I will put a short message explaining how WeatherfordFitness uses MtnTop, you know, what their skill/action name is, what questions they support, their purpose in using it, etc etc etc..",
  },
  {
    id: "hope-college",
    businessTitle: "Hope College",
    logoImage: hopeLogo,
    whoTheyAre:
      "This is where I will put a short message explaining who Hope is, you know, what kind of organization, where, what size, purpose, etc etc etc.",
    howTheyUse:
      "This is where I will put a short message explaining how Hope College uses MtnTop, you know, what their skill/action name is, what questions they support, their purpose in using it, etc etc etc..",
  },
  {
    id: "holland-peanut",
    businessTitle: "Holland Peanut Store",
    logoImage: peanutLogo,
    whoTheyAre:
      "This is where I will put a short message explaining who the Holland Peanut Store is, you know, what kind of organization, where, what size, purpose, etc etc etc.",
    howTheyUse:
      "This is where I will put a short message explaining how the Holland Peanut Store uses MtnTop, you know, what their skill/action name is, what questions they support, their purpose in using it, etc etc etc..",
  },
  {
    id: "weatherford-fit",
    businessTitle: "Weatherford Fitness",
    logoImage: weatherfordLogo,
    whoTheyAre:
      "This is where I will put a short message explaining who Weatherford Fitness is, you know, what kind of organization, where, what size, purpose, etc etc etc.",
    howTheyUse:
      "This is where I will put a short message explaining how WeatherfordFitness uses MtnTop, you know, what their skill/action name is, what questions they support, their purpose in using it, etc etc etc..",
  },
];
export { CASE_STUDIES };
