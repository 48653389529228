import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import LoginButton from "../../components/LoginButton/LoginButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SunsetPage from "../SunsetPage/SunsetPage";
import {
  faArrowCircleRight,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";
//import Loader from "react-loader-spinner";
import classes from "./LandingPage.module.scss";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import dev_config from "../../config_dev";
import prod_config from "../../config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

const LandingPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const titleTransitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
  };

  const logoDefaultStyle = {
    transition: `max-height 1s`,
    maxHeight: "calc(50% - 160px)",
    height: "calc(50% - 160px)",
  };

  const logoTransitionStyles = {
    entering: { maxHeight: "calc(50% - 160px)" },
    entered: { maxHeight: "10px" },
  };

  const questionsDefaultStyle = {
    transition: `1000ms maxHeight ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <React.Fragment>
      <div className={`${classes.FullScreen}`}>
        <Transition
          in={inProp}
          timeout={{
            appear: 0,
            enter: 0,
            exit: 0,
          }}
          appear
          unmountOnExit
        >
          {(state) => (
            <React.Fragment>
              <LoginButton hide={state === "entering"}></LoginButton>
              <div
                style={{
                  ...logoDefaultStyle,
                  ...logoTransitionStyles[state],
                }}
              ></div>
            </React.Fragment>
          )}
        </Transition>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            //alignItems: "center",
          }}
        >
          <div className={classes.LogoImage}>
            <Image src={logo} fluid />

            <Transition
              in={inProp}
              timeout={{
                appear: 0,
                enter: 0,
                exit: 0,
              }}
              appear
              unmountOnExit
            >
              {(state) => (
                <div
                  style={{
                    ...titleDefaultStyle,
                    ...titleTransitionStyles[state],
                  }}
                  className={`${classes.LogoText}`}
                >
                  MtnTop
                </div>
              )}
            </Transition>
          </div>
        </div>
        <Transition
          in={inProp}
          timeout={{
            appear: 0,
            enter: 0,
            exit: 0,
          }}
          appear
          unmountOnExit
        >
          {(state) => (
            <div>
              <div
                style={{
                  ...questionsDefaultStyle,
                  ...questionsTransitionStyles[state],
                }}
                className={classes.QuestionText}
              >
                {/* Shout your message from the{" "}
              <span style={{ fontWeight: "bold" }}>MTNTOP</span> */}

                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    backgroundColor: "#2C5985",
                    alignSelf: "center",
                    display: "inline-block",
                    padding: 5,
                    paddingLeft: 12,
                    paddingRight: 12,

                    borderRadius: 5,
                    margin: 10,
                  }}
                >
                  Connect with your audience on{" "}
                  <span style={{ color: "white" }}>Alexa</span> and{" "}
                  <span style={{ color: "white" }}>Google Assistant</span>{" "}
                </div>
                {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className={classes.TitleDivider}></div>
              </div> */}
                {/* <div
             style={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
             }}
           >
             <div
               onClick={() => {
                //console.log("clicked get started");
                 //setRedirectUrl("/auth");
                 //history.push("/google-instructions");
               }}
               className={`${classes.GetStartedButton}`}
               style={{}}
             >
               <div>Get Started</div>
             </div>
           </div> */}
                <div className="row" style={{ flex: "1 1 auto" }}>
                  <div
                    className={`col-sm-12 col-lg-4 col-md-4 ${classes.LeftRowItem}`}
                  >
                    <div
                      onClick={() => {
                        //console.log("clicked listen");
                        //history.push("/select-device");
                      }}
                      className={`${classes.AnswerText}`}
                    >
                      <div className={classes.GroupTitle}>Customize</div>
                      <div className={classes.AnswerSubtext}>
                        Choose the name of your Alexa skill and Google action
                        and what questions customers can ask it
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-sm-12 col-lg-4 col-md-4 ${classes.CenterItem}`}
                  >
                    <div className={`${classes.AnswerText}`}>
                      <div className={classes.GroupTitle}>Post</div>
                      <div className={classes.AnswerSubtext}>
                        Use the{" "}
                        <span className={classes.PurpleInlineText}>MtnTop</span>{" "}
                        app to create and post answers to your questions.
                        Answers can be recorded in your own voice, or written to
                        be spoken by Alexa or Google.
                      </div>
                      <div className={classes.AnswerSubicon}>
                        <FontAwesomeIcon
                          icon={faArrowCircleRight}
                          style={{ fontSize: "25", margin: "5px" }}
                        ></FontAwesomeIcon>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-sm-12 col-lg-4 col-md-4 ${classes.RightRowItem}`}
                  >
                    <div className={`${classes.AnswerText}`}>
                      <div className={classes.GroupTitle}>Connect</div>
                      <div className={classes.AnswerSubtext}>
                        Listeners can hear the content you have posted by asking
                        for you on Alexa or Google Assistant!
                      </div>
                      <div className={classes.AnswerSubicon}>
                        <FontAwesomeIcon
                          icon={faArrowCircleRight}
                          style={{ fontSize: "25", margin: "5px" }}
                        ></FontAwesomeIcon>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    //backgroundColor: "whitesmoke",
                  }}
                >
                  <div
                    onClick={() => {
                      //console.log("clicked get started");
                      //setRedirectUrl("/auth");
                      history.push("/pre-info");
                    }}
                    className={`${classes.GetStartedButton}`}
                    style={{}}
                  >
                    <div>
                      {`Learn More${dev_config.useProd ? "" : "-dev"}`}{" "}
                    </div>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{
                        fontSize: "20",
                        margin: "5px",
                        marginLeft: "15px",
                      }}
                    ></FontAwesomeIcon>
                  </div>
                </div>
              </div>
              <SunsetPage />
            </div>
          )}
        </Transition>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
