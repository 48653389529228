import React, { useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { writeLog } from "../../shared/logger";
import TextInput from "../../components/TextInput/TextInput";
import SubmitButton from "../../components/SubmitButton/SubmitButton";

import logo from "../../assets/images/holyminute_logo_big.png";
import Image from "react-bootstrap/Image";

import classes from "./Login.module.scss";
import { updateObject, checkValidity } from "../../shared/utility";
import { LOGIN_CONTROLS, SIGNUP_CONTROLS } from "./LoginFormConfig";

import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../store/actions/auth";
import dev_config from "../../config_dev";
import prod_config from "../../config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

export default function Login(props) {
  const dispatch = useDispatch();
  const isVerified = useSelector((state) => !!state.authStore.isVerified);

  const history = useHistory();
  let location = useLocation();

  const startedAsSignup =
    location && location.search && location.search === "?mode=sign-up";
  //console.log("LOCATION: ", location);

  const initialControls = startedAsSignup ? SIGNUP_CONTROLS : LOGIN_CONTROLS;

  const [authForm, setAuthForm] = useState(initialControls);

  const [isLoggingIn, setIsLoggingIn] = useState(!startedAsSignup);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSuccessfullySubmitted, setHasSuccessfullySubmitted] = useState(
    false
  );

  const inputChangedHandler = (event, controlName) => {
    setLoginErrorMessage("");

    const { validity, errorMessage } = checkValidity(
      event.target.value,
      authForm[controlName].validation
    );
    //console.log("have validity: ", validity);
    //console.log("have error message: ", errorMessage);

    const updatedControls = updateObject(authForm, {
      [controlName]: updateObject(authForm[controlName], {
        value: event.target.value,
        valid: validity,
        invalidMessage: errorMessage,
      }),
    });
    setAuthForm(updatedControls);
  };

  const signUpClicked = () => {
    //console.log("sign up clicked");

    if (isLoggingIn) {
      setAuthForm(SIGNUP_CONTROLS);
    } else {
      setAuthForm(LOGIN_CONTROLS);
    }
    setLoginErrorMessage("");
    setSubmitAttempted(false);
    setIsLoggingIn(!isLoggingIn);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setSubmitAttempted(true);

    if (!allValid) return;

    if (!isLoggingIn && authForm[4].value !== authForm[5].value) {
      setLoginErrorMessage("Passwords must match");
      return;
    }

    setIsLoading(true);

    try {
      if (isLoggingIn) {
        await dispatch(authActions.login(authForm[1].value, authForm[2].value));
      } else {
        await dispatch(
          authActions.signUp(authForm[3].value, authForm[4].value)
        );
      }

      setIsLoading(false);
      setHasSuccessfullySubmitted(true);
    } catch (e) {
      if (e.message.startsWith("PreSignUp failed with error")) {
        //setIsLoading(false);
        history.push("/prereg-required");
        //setLoginErrorMessage("Email provided has not been preregistered");
      } else {
        setLoginErrorMessage(e.message);
        setIsLoading(false);
      }

      //alert(e.message);
    }
  }

  const onSubmissionFinish = () => {
    //setTriggerRedirect(true);
    if (isVerified) {
      history.push("/account");
    } else {
      history.push("/confirmation-required");
    }
  };

  let allValid = true;
  let allFilled = true;
  const formElementsArray = [];
  for (let key in authForm) {
    formElementsArray.push({
      id: key,
      config: authForm[key],
    });
    allFilled = allFilled && authForm[key].value;
    allValid = allValid && authForm[key].valid;
  }

  let inputs = formElementsArray.map((formElement) => (
    <div className={classes.InputContainer} key={formElement.id}>
      <TextInput
        id={formElement.id}
        type={formElement.config.elementConfig.type}
        label={formElement.config.elementConfig.placeholder}
        locked={isLoading || hasSuccessfullySubmitted}
        active={false}
        setValue={(event) => inputChangedHandler(event, formElement.id)}
        shouldValidate={formElement.config.validation}
        error={
          formElement.config.validation &&
          !formElement.config.valid &&
          submitAttempted &&
          formElement.config.invalidMessage
        }
        value={formElement.config.value}
        clearFormSubmissionError={() => {
          setLoginErrorMessage("");
        }}
      />
    </div>
  ));

  const submitButton = (
    <SubmitButton
      disabled={!allFilled}
      type="submit"
      isLoading={isLoading}
      hasSuccessfullySubmitted={hasSuccessfullySubmitted}
      onClick={handleSubmit}
      onSubmissionFinish={onSubmissionFinish}
    >
      {isLoggingIn ? "LOG IN" : "SIGN UP"}
    </SubmitButton>
  );

  return (
    <React.Fragment>
      <div className={`${classes.FullScreen} row`}>
        <div className={`col-sm-12 col-lg-6 col-md-6 ${classes.LeftRowItem}`}>
          <div className={classes.LogoImage}>
            {/* <img src={logo} className={"img-fluid"} /> */}
            <Image src={logo} fluid />
          </div>
        </div>
        <div
          className={`col-sm-12 col-lg-6 col-md-6 ${classes.RightRowItem} ${
            isLoggingIn ? "" : classes.RightRowItemSignup
          }`}
        >
          <form
            onSubmit={handleSubmit}
            className={`${classes.LoginForm} ${
              isLoggingIn ? "" : classes.LoginFormWithSignUp
            } `}
          >
            {/* <div className={classes.Title} key={20}>
              {config.loginTitle}
            </div> */}
            {inputs}
            {submitButton}
            <div className={classes.ToggleButtonContainer}>
              <div
                className={classes.ToggleSignupButton}
                onClick={signUpClicked}
              >
                {isLoggingIn ? "SIGN UP" : "LOG IN"}
              </div>
            </div>
            {loginErrorMessage && (
              <div className={classes.FailedLoginMessage}>
                {loginErrorMessage}
              </div>
            )}
            {/* </div> */}
          </form>
        </div>
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            flex: 1,
            alignItems: "flex-end",
            display: "flex",
          }}
        >
          <div
            className={classes.ForgotPasswordButtonContainer}
            onClick={signUpClicked}
          >
            <span className={classes.ForgotPasswordButton}>Help! I</span>
            <span
              className={classes.ForgotPasswordButtonUnderlined}
              onClick={() => {
                history.push("/forgot-password");
              }}
            >
              forgot my password
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
