export default {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://4rflw4hg9f.execute-api.us-east-1.amazonaws.com/prod",
    API_NAME: "mtntop-prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Pa7z5GNC6",
    APP_CLIENT_ID: "2v1us97cgufpa19gtbd5n6f5g1",
    IDENTITY_POOL_ID: "us-east-1:3f0211ce-a3b0-40ad-b0a6-9a189807803b",
  },
  loginTitle: "MtnTop",
  useLogs: false,
  stripePk: "placeholder",
};
