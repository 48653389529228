export default {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://jq7vpyz5wf.execute-api.us-east-1.amazonaws.com/dev",
    API_NAME: "mtntop-dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_GlibSUP22",
    APP_CLIENT_ID: "tb2jk6jgd1hr1bnnkq6opstlg",
    IDENTITY_POOL_ID: "us-east-1:ae7962a0-f97f-4b8f-85ca-f1af410cddaf",
  },
  loginTitle: "MtnTop-dev",
  useProd: true,
  useLogs: true,
  stripePk: "placeholder",
};
