import React from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useHistory,
} from "react-router-dom";
import "./App.scss";
import Login from "./containers/Login/Login";
import AccountHomePage from "./containers/AccountHomePage/AccountHomePage";
import CollectPaymentPage from "./containers/CollectPaymentPage/CollectPaymentPage";
import SpreadTheWordPage from "./containers/SpreadTheWordPage/SpreadTheWordPage";
import MyPeaksPage from "./containers/MyPeaksPage/MyPeaksPage";
import CreatorTutorialPage from "./containers/CreatorTutorialPage/CreatorTutorialPage";
import MyAccountPage from "./containers/MyAccountPage/MyAccountPage";
import LandingPage from "./containers/LandingPage/LandingPage";
import AllCaseStudiesPage from "./containers/AllCaseStudiesPage/AllCaseStudiesPage";
import CaseStudyDetailsPage from "./containers/CaseStudyDetailsPage/CaseStudyDetailsPage";
import PrivacyPolicyPage from "./containers/PrivacyPolicyPage/PrivacyPolicyPage";
import CreatorPreinfoPage from "./containers/CreatorPreinfoPage/CreatorPreinfoPage";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import AccountConfirmed from "./containers/AccountConfirmed/AccountConfirmed";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import ConfirmationInstructions from "./containers/ConfirmationInstructions/ConfirmationInstructions";
import PreregistrationRequired from "./containers/PreregistrationRequired/PreregistrationRequired";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import collectionsReducer from "./store/reducers/collections";
import authReducer from "./store/reducers/auth";
import exampleReducer from "./store/reducers/exampleStore";
import userReducer from "./store/reducers/userStore";
import dev_config from "./config_dev";
import prod_config from "./config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

const rootReducer = combineReducers({
  collectionsStore: collectionsReducer,
  authStore: authReducer,
  userStore: userReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

const stripePromise = loadStripe(config.stripePk);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <div className="App">
          <header className="App-header">
            <Switch>
              <Route path="/auth" exact render={(props) => <Login></Login>} />
              <Route
                path="/privacy-policy"
                exact
                render={(props) => <PrivacyPolicyPage></PrivacyPolicyPage>}
              />
              <Route
                path="/forgot-password"
                exact
                render={(props) => <ForgotPassword></ForgotPassword>}
              />
              <Route
                path="/reset/:code/user/:userName"
                exact
                render={(props) => <ResetPassword></ResetPassword>}
              />
              <Route
                path="/confirm-account/:code/user/:userName"
                exact
                render={(props) => <AccountConfirmed></AccountConfirmed>}
              />
              <Route
                path="/confirmation-required"
                exact
                render={(props) => (
                  <ConfirmationInstructions></ConfirmationInstructions>
                )}
              />
              <Route
                path="/account"
                exact
                render={(props) => <AccountHomePage></AccountHomePage>}
              />
              <Route
                path="/collect-payment"
                exact
                render={(props) => <CollectPaymentPage></CollectPaymentPage>}
              />
              <Route
                path="/spread-the-word"
                exact
                render={(props) => <SpreadTheWordPage></SpreadTheWordPage>}
              />
              <Route
                path="/my-peaks"
                exact
                render={(props) => <MyPeaksPage></MyPeaksPage>}
              />
              <Route
                path="/get-started"
                exact
                render={(props) => <CreatorTutorialPage></CreatorTutorialPage>}
              />
              <Route
                path="/manage-account"
                exact
                render={(props) => <MyAccountPage></MyAccountPage>}
              />
              <Route
                path="/pre-info"
                exact
                render={(props) => <CreatorPreinfoPage></CreatorPreinfoPage>}
              />
              {/* <Route
                path="/case-studies"
                exact
                render={(props) => <AllCaseStudiesPage></AllCaseStudiesPage>}
              />
              <Route
                path="/case-study/:caseStudyId"
                exact
                render={(props) => (
                  <CaseStudyDetailsPage></CaseStudyDetailsPage>
                )}
              /> */}
              <Route
                path="/prereg-required"
                exact
                render={(props) => (
                  <PreregistrationRequired></PreregistrationRequired>
                )}
              />
              <Route
                path="/"
                exact
                render={(props) => <LandingPage></LandingPage>}
              />
              {/* <Route path="/" exact render={(props) => <HomePage></HomePage>} /> */}
              {/* <Route
              path="/home1"
              exact
              render={(props) => (
                <ProtectedContent title="1"></ProtectedContent>
              )}
            />
            <Route
              path="/home2"
              exact
              render={(props) => (
                <ProtectedContent title="2"></ProtectedContent>
              )}
            /> */}
              <Redirect to="/" />
            </Switch>
            {/* <Login></Login> */}
            {/* <ProtectedContent></ProtectedContent> */}
            {/* <ResetPassword></ResetPassword> */}
            {/* <AccountConfirmed></AccountConfirmed> */}
            {/* <ForgotPassword></ForgotPassword> */}
          </header>
        </div>
      </Provider>
    </Elements>
  );
}

export default withRouter(App);
