import React from "react";

import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy";

//import classes from "./PrivacyPolicyPage.module.scss";

const PrivacyPolicyPage = (props) => {
  return (
    <div>
      {/* <h1>Home Page</h1> */}
      {/* <ProtectedContent></ProtectedContent> */}
      <PrivacyPolicy></PrivacyPolicy>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
    </div>
  );
};

export default PrivacyPolicyPage;
