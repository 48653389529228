import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./SpreadTheWordPage.module.scss";

const SpreadTheWordPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            MtnTop
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            <div
              style={{
                //borderWidth: 1,
                //border: "solid",
                padding: 5,
                backgroundColor: "#2c5986",
                //backgroundColor: "white",
                paddingLeft: 20,
                paddingRight: 20,
                display: "inline-block",
                borderRadius: 5,
                //color: "#2c5986",
                marginBottom: 8,
                fontWeight: "bold",
              }}
            >
              Spread the Word
            </div>
            <div className={classes.SubQuestionText}>
              How to let your listeners know you are on Alexa and Google
              Assistant and help them find you there{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    When you want to tell people that your business or
                    organization is now on Alexa and Google Assistant, your
                    communications should include the following:
                    <br></br>
                    <br></br>
                    1.{" "}
                    <span className={classes.SpokenWords}>
                      Your MtnTop name
                    </span>{" "}
                    - your listeners will need to know the name you have
                    selected for your Alexa skill and Google Action so they can
                    find you on their voice assistant.
                    <br></br>
                    <br></br>
                    2.{" "}
                    <span className={classes.SpokenWords}>
                      Sample phrasing for Alexa and Google
                    </span>{" "}
                    - provide your audience with some example phrases to get
                    them started using your skill/action and to give them an
                    idea of what kind of information they can expect to find on
                    it.
                    <br></br>
                    <br></br>
                    3.{" "}
                    <span className={classes.SpokenWords}>
                      The range of devices
                    </span>{" "}
                    - you will want to make sure people know that they can
                    access your MtnTop from any Alexa or Google
                    Assistant-enabled device, from smart speakers to cars to
                    moblie phones!
                    <br></br>
                    <br></br>
                    4.{" "}
                    <span className={classes.SpokenWords}>
                      The phrase "Built on MtnTop"
                    </span>{" "}
                    - this phrase must be included in some way in all
                    communications regarding your MtnTop.
                    <br></br>
                    <br></br>
                    {/* <span style={{ fontStyle: "italic" }}>Nice to have:</span>
                    <br></br>
                    <span className={classes.SpokenWords}>
                      The MtnTop Logo
                    </span>{" "}
                    - this will help users know they have come to the right
                    place anytime they see this logo
                    <br></br>
                    <span className={classes.SpokenWords}>
                      Posting frequency
                    </span>{" "}
                    - it's a good idea to let people know how often you expect
                    to be posting new content so they know what to expect
                    <br></br>
                    <br></br> */}
                    <div style={{ textAlign: "center" }}>
                      <span
                        className={classes.SpokenWords}
                        style={{ fontSize: 28 }}
                      >
                        Example:
                      </span>
                    </div>{" "}
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    <span className={classes.SpokenWords}>
                      Connect with Waffle Cone Ice Cream on Alexa and Google
                      Assistant!
                    </span>{" "}
                    <br></br>
                    We are excited to announce that Waffle Cone Ice Cream is now
                    on Alexa and Google Assistant. Just say, "Alexa, enable
                    Waffle Cone Ice Cream" or "Hey Google, talk to Waffle Cone
                    Ice Cream" to stay caught up on everything happening at the
                    ice cream stand, from up-to-date hours information to the
                    inside scoop on the flavor of the day!{"  "}
                    <br></br>
                    <span
                      className={`${classes.SpokenWords} ${classes.TrademarkPhrase}`}
                      style={{
                        fontWeight: "bold",
                        //marginLeft: 4,
                        //marginRight: 4,
                        opacity: 0.6,
                        fontSize: 10,
                        marginTop: 0,
                        paddingTop: 0,
                        lineHeight: 0.5,
                        display: "inline-block",
                      }}
                    >
                      Built on MtnTop
                    </span>
                    {"  "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default withAuth(SpreadTheWordPage);
