import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import classes from "./LoginButton.module.scss";

const LoginButton = (props) => {
  const history = useHistory();

  return (
    <div
      className={classes.LoginButton}
      style={props.hide ? { opacity: 0 } : {}}
      onClick={() => {
        history.push("/auth", { isLogin: true });
      }}
    >
      Log In
    </div>
  );
};

export default LoginButton;
