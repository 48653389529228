import { Auth } from "aws-amplify";
import { writeLog } from "../../shared/logger";
import * as authActions from "./auth";
import { apiGet, apiPost } from "../../shared/apiWrapper";
import dev_config from "../../config_dev";
import prod_config from "../../config_prod";
const config = dev_config.useProd ? prod_config : dev_config;
export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_FAILED = "LOAD_USER_FAILED";
export const SET_PAID = "SET_PAID";

const UNAUTHED_ERROR_MESSAGE = "No current user";

export const loadUser = () => {
  return async (dispatch) => {
    try {
      const response = await apiGet(config.apiGateway.API_NAME, `/user/self`);
      //console.log("response.user: ", response.user);

      // const response = {
      //   user: {
      //     hasPaid: false,
      //     userDisplayName: "mike",
      //     userEmail: "michael.kiley7@gmail.com",
      //     userId: "e930e008-e479-4df9-ac97-bc623ad576dd",
      //   },
      // };

      dispatch({
        type: LOAD_USER,
        user: response.user,
      });
      writeLog("have loaded user: ", response.user);
    } catch (error) {
      //console.log("error loading user: ", error);
      if (error === UNAUTHED_ERROR_MESSAGE) {
        dispatch(authActions.setSignedOut());
      } else {
        dispatch({
          type: LOAD_USER_FAILED,
        });
      }
      //throw error;
    }
  };
};

export const setPaid = (paid) => {
  return async (dispatch) => {
    try {
      const response = await apiPost(
        config.apiGateway.API_NAME,
        `/user/self/paid`,
        {
          paid: paid,
        }
      );
      dispatch({
        type: SET_PAID,
        paid: paid,
      });
    } catch (error) {
      //console.log(`error setting paid to${paid} for user`, error);
      if (error === UNAUTHED_ERROR_MESSAGE) {
        dispatch(authActions.setSignedOut());
      }
      //throw error;
    }
  };
};
