import React, { useState, useEffect } from "react";
import { Spinner, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { writeLog } from "../../shared/logger";

import classes from "./PreregistrationRequired.module.scss";

import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

import { useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";

const EXPIRED_CODE_EXCEPTION = "ExpiredCodeException";

export default function PreregistrationRequired() {
  const history = useHistory();

  const preregRequiredMessage = (
    <React.Fragment>
      <ErrorMessage
        title="Preregistration Required!"
        subtitle={
          "All signups must first go through a preregistration process! We have been notified of your sign-up attempt and will be reaching out, so watch for an email from mtntop@harbourviewtechnologies.com!"
        }
        hideIcon
      ></ErrorMessage>
      <div className={classes.ButtonContainer}>
        <Button
          size="lg"
          className={classes.BackToSignUpButton}
          onClick={() => {
            //console.log("clicked back to login");
            history.push("/auth", { isSignUp: true });
          }}
        >
          Back to Sign Up
        </Button>
      </div>
    </React.Fragment>
  );

  return preregRequiredMessage;
}
