import React, { useState, useEffect } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { Redirect, useHistory } from "react-router-dom";
import ProtectedContent from "../ProtectedContent/ProtectedContent";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { withAuth } from "../../hoc/Auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_no_padding.png";
import Image from "react-bootstrap/Image";

import classes from "./CreatorTutorialPage.module.scss";

const CreatorTutorialPage = (props) => {
  //const [redirectUrl, setRedirectUrl] = useState("");
  let history = useHistory();

  const inProp = true;

  const titleDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
  };

  const questionsDefaultStyle = {
    transition: `1000ms opacity ease`,
    opacity: 0,
    overflowY: "visible",
    overflowX: "hidden",
  };

  const questionsTransitionStyles = {
    entering: { opacity: 0, overflowY: "hidden" },
    entered: { opacity: 1, overflowY: "visible", overflowX: "hidden" },
  };

  // if (redirectUrl) {
  //   return <Redirect to={redirectUrl}></Redirect>;
  // }

  return (
    <div className={`${classes.FullScreen}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //alignItems: "center",
        }}
      >
        <div className={classes.LogoImage}>
          <Image src={logo} fluid />

          <div
            style={{
              ...titleDefaultStyle,
            }}
            className={`${classes.LogoText}`}
          >
            MtnTop
          </div>
        </div>
      </div>
      <Transition
        in={inProp}
        timeout={{
          appear: 100,
          enter: 1000,
          exit: 300,
        }}
        appear
        unmountOnExit
      >
        {(state) => (
          <div
            style={{
              ...questionsDefaultStyle,
              ...questionsTransitionStyles[state],
            }}
            className={classes.QuestionText}
          >
            <div
              style={{
                //borderWidth: 1,
                //border: "solid",
                padding: 5,
                backgroundColor: "#2c5986",
                //backgroundColor: "white",
                paddingLeft: 20,
                paddingRight: 20,
                display: "inline-block",
                borderRadius: 5,
                //color: "#2c5986",
                marginBottom: 8,
                fontWeight: "bold",
              }}
            >
              Get Started
            </div>
            <div className={classes.SubQuestionText}>
              How to post create and post MtnTop content{" "}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  display: "block",
                  flexDirection: "column",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                <div className={`${classes.InstructionText}`}>
                  <div>
                    1. Download the MtnTop app from the{" "}
                    <a
                      className={classes.Link}
                      href="https://apps.apple.com/us/app/id1527184612"
                    >
                      {/* <a className={classes.Link} href="https://google.com"> */}
                      Apple App Store
                    </a>{" "}
                    or the{" "}
                    <a
                      className={classes.Link}
                      href="https://play.google.com/store/apps/details?id=com.mtntop"
                    >
                      Google Play Store
                    </a>
                    {/* <a className={classes.Link} href="https://google.com">
                      Google Play Store
                    </a> */}
                    .
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    2. Sign in with your MtnTop account.<br></br>
                    The app includes a walkthrough at the beginning - the
                    following is largely a repetition of the information
                    included there!
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    3. Press the blue microphone button on the{" "}
                    <span className={classes.SpokenWords}>Record</span> tab to
                    record audio content to post. Recordings can be a maximum of
                    4 minutes in length.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    4. Press the blue pen button on the{" "}
                    <span className={classes.SpokenWords}>Write</span> tab to
                    create written content to post. This is content that will be
                    read aloud by Alexa or Google Assistant in their default
                    voices, where your audio content will be played as-is.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    5. The <span className={classes.SpokenWords}>Post</span> tab
                    shows you the list of{" "}
                    <span className={classes.SpokenWords}>Peaks</span> your
                    MtnTop has - peaks are the questions you requested when we
                    set up your MtnTop. Each{" "}
                    <span className={classes.SpokenWords}>Peak</span> has a
                    schedule associated with it which allows you to set what
                    content from your Audio and Text libraries will be played
                    for listeners on what days. Click on a peak to view its
                    schedule.
                  </div>
                </div>
                <div className={`${classes.InstructionText}`}>
                  <div>
                    6. To post content that you have recorded or written, go to
                    the schedule for the desired peak and press the plus button
                    next to the day you want the content to be available on. You
                    can also set a default post for the schedule, which will be
                    the content that is used for days when you don't have a
                    specific post on your schedule. <br></br>
                  </div>
                </div>

                <div className={`${classes.InstructionText}`}>
                  <div>
                    <span
                      className={classes.SpokenWords}
                      style={{ marginLeft: 0 }}
                    >
                      Tips:
                    </span>{" "}
                    <br></br>
                    <br></br>You don't have to have something posted for every
                    single day if you don't want to! Your MtnTop is built to
                    allow for that, and will simply let the user know that you
                    haven't posted anything yet if they ask that specific
                    question.
                    <br></br>
                    <br></br>
                    Default posts are great for stretching a post over multiple
                    days. Multi-day posts can also be accomplished by posting
                    the same saved content to multiple specific days on a
                    schedule
                    <br></br>.<br></br>If you have any questions, don't hesitate
                    to email us!
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </div>
        )}
      </Transition>
    </div>
  );
};

export default withAuth(CreatorTutorialPage);
